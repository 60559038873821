import { Fragment, useEffect, useState } from 'react'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import PlaceSearchResults from '../PlaceSearchResults'
import classNames from 'classnames'

import './style.css'

const popupStyle = {
	backgroundColor: '#fff',
	borderRadius: 20,
	width: '100%',
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	boxShadow: 'rgba(0, 0, 0, 0.14) 0px 3px 6px',
}

const routeChooserStyle = {
	backgroundColor: '#fff',
	borderRadius: 20,
	width: '100%',
	boxSizing: 'border-box',
	display: 'flex',
	fontSize: 14,
	padding: '16px 30px',
	gap: 16,
	boxShadow: 'rgba(0, 0, 0, 0.14) 0px 3px 6px',
}

const routeTypes = {
	commute_to_education: 'Schulweg',
	commute_to_work: 'Arbeitsweg',
	business_visit: 'Geschäftsbesuch',
	transportation_as_occupation: 'Transportwege (als Beruf)',
	cafe_restaurant: 'Besuch Café, Restaurant',
	shopping_daily_products: 'Alltagseinkauf',
	friend_family: 'Freunde- und Familienbesuch',
	shopping_nondaily_products: 'Nicht-Alltagseinkauf',
	commute_to_nursery_preschool_school: 'Kinder zur Kita/Schule bringen',
	chauffeuring_person: 'Wege als Chauffeur (nicht beruflich)',
	accommodation: 'Hotel, AirBnB',
	medical_service: 'Zahnartz-, Arzt-, Spitalbesuch',
	sport_recreation: 'Sportbesuch, Erholung',
	culture: 'Kino-, Theater-, Museumsbesuch',
	other_education: 'Andere Ausbildung',
	other_leisure: 'Andere Freizeitaktivität',
	social_visit: 'Sonstiger sozialer Besuch',
	other_service: 'Sonstige Dienstleistung',
	public_transport_station: 'Haltestelle, Bahnhof',
	wait_meet: 'Verabredung, Warten',
}

export default function MyRoutes(props) {
	const [routeToDelete, setRouteToDelete] = useState(null)
	const [routeName, setRouteName] = useState('Hinweg')
	const [isRouteNameInputVisible, setIsRouteNameInputVisible] = useState(false)
	const [routeType, setRouteType] = useState('commute_to_education')
	const [placeSearchOverviewActive, setPlaceSearchOverviewActive] = useState(false)
	const [mouseOverWaypointInputs, setMouseOverWaypointInputs] = useState(false)

	useEffect(() => {
		// If journey is routed, close place search
		setPlaceSearchOverviewActive(false)
		props.onSearchClose()
		props.setPlaceSelected(null)
	}, [props.journeyFeatureCollection])

	useEffect(() => {
		// Reset state when route menu is opened, route type changes, or tab changes
		if (props.selectedTab === 'createRoute' && props.selectedUserRoute) {
			setRouteName(props.selectedUserRoute.name)
			setIsRouteNameInputVisible(true)
			setRouteType(props.selectedUserRoute.type)
		} else {
			setRouteName('Hinweg')
			setIsRouteNameInputVisible(false)
			setRouteType('commute_to_education')
			props.setSelectedUserRoute(null)
		}
	}, [props.myRoutesOpened, props.routeTypeChooser, props.selectedTab, props.selectedUserRoute])

	const goToSearch = () => {
		props.onSearchFocus()
		setPlaceSearchOverviewActive(true)
		if (props.searchRef.current) {
			props.searchRef.current.focus()
		}
	}

	const handleDeleteWaypoint = index => {
		props.onDeleteWaypoint(index)
	}

	const revertWaypoints = () => {
		props.setMyRouteStops({
			start: {
				...props.myRouteStops.destination,
				features: props.myRouteStops.destination.features.map(destination => ({
					...destination,
					properties: {
						...destination.properties,
						enum: 'A',
					},
				})),
			},
			destination: {
				...props.myRouteStops.start,
				features: props.myRouteStops.start.features.map(start => ({
					...start,
					properties: {
						...start.properties,
						enum: 'B',
					},
				})),
			},
			viaStops: {
				...props.myRouteStops.viaStops,
				features: [...props.myRouteStops.viaStops.features].reverse().map((viaStop, index) => ({
					...viaStop,
					properties: {
						...viaStop.properties,
						enum: index + 1,
					},
				})),
			},
		})
	}

	const renderRouteList = () => {
		if (!props.userRoutes.length) {
			return <div className='routeListItemNotFound'>Keine Routen gefunden</div>
		}

		return props.userRoutes.map(route => {
			const start = route.geojson.features.find(feature => feature.properties.type === 'start')
			const destination = route.geojson.features.find(feature => feature.properties.type === 'end')
			return (
				<div
					key={route.id}
					onClick={() => props.onUserRouteClicked(route)}
					className='routeListItem'
					style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<div
						className={classNames('myRoutes--routeListItemWrapper', {
							'myRoutes--hidden': routeToDelete && routeToDelete.id === route.id,
						})}>
						<div
							style={{
								borderRadius: '50%',
								marginRight: 4,
								width: 16,
								height: 16,
								backgroundColor: route.color,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							{route.selected && (
								<svg stroke='#fff' fill='#fff' viewBox='0 0 512 512' height='12px' width='12px'>
									<path d='M186.301 339.893L96 249.461l-32 30.507L186.301 402 448 140.506 416 110z'></path>
								</svg>
							)}
						</div>
						<div className='myRoutes--routeDetailWrapper'>
							<div className='myRoutes--routeName'>
								{start?.properties.name} → {destination?.properties.name}
							</div>
							<div className='myRoutes--routeDetail'>{routeTypes[route.type]}</div>
							<div className='myRoutes--routeDetail'>
								{route.name} • {route.distance} km
							</div>
						</div>
						<div>
							<IconButton
								size='small'
								onClick={event => {
									event.stopPropagation()
									props.onEditRoute(route)
								}}>
								<EditIcon fontSize='inherit' />
							</IconButton>
							<IconButton
								size='small'
								onClick={event => {
									event.stopPropagation()
									setRouteToDelete(route)
								}}>
								<DeleteIcon fontSize='inherit' />
							</IconButton>
						</div>
					</div>
					{routeToDelete && routeToDelete.id === route.id && (
						<div className='myRoutes--deleteRouteWrapper'>
							<button
								className='myRoutes--routeDeleteButton'
								onClick={evt => {
									evt.stopPropagation()
									props.onDeleteRoute(route.id)
								}}>
								Löschen
							</button>
							<button
								onClick={evt => {
									evt.stopPropagation()
									setRouteToDelete(null)
								}}>
								Abbrechen
							</button>
						</div>
					)}
				</div>
			)
		})
	}

	const renderMyRoutes = () => {
		return (
			<div>
				<div
					style={{
						overflow: 'auto',
						maxHeight: 250,
						fontSize: 14,
						display: 'flex',
						flexDirection: 'column',
						margin: '0 -8px',
					}}>
					{renderRouteList()}
				</div>
			</div>
		)
	}

	const renderStartWaypoint = () => {
		const waypointA = {
			enum: 'A',
			name: !props.myRouteStops.start.features[0]
				? 'Startpunkt eingeben'
				: props.myRouteStops.start.features[0]?.properties?.name
					? props.myRouteStops.start.features[0].properties.name
					: 'Waypoint A',
		}
		return (
			<div className='myRoutes--waypointItemWrapper'>
				<div
					className='myRoutes--waypointItem'
					onClick={() => {
						props.startInputFocused.current = true
						goToSearch()
					}}>
					<div className='myRoutes--waypointTitleWrapper'>
						<div className='myRoutes--waypointEnum'>{waypointA.enum}</div>
						<div className='myRoutes--waypointTitle'>{waypointA.name}</div>
					</div>
				</div>
				<div
					onClick={() => {
						handleDeleteWaypoint(0)
					}}
					className='myRoutes--waypointDeleteIcon'
					style={{ width: mouseOverWaypointInputs ? 24 : 0 }}>
					<svg
						fill='#afafaf'
						viewBox='0 0 24 24'
						height={mouseOverWaypointInputs ? 16 : 0}
						width={mouseOverWaypointInputs ? 16 : 0}>
						<path fill='none' d='M0 0h24v24H0z'></path>
						<path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z'></path>
					</svg>
				</div>
			</div>
		)
	}

	const renderDestinatonWaypoint = () => {
		const waypointB = {
			enum: 'B',
			name: !props.myRouteStops.destination.features[0]
				? 'Ziel eingeben'
				: props.myRouteStops.destination.features[0]?.properties?.name
					? props.myRouteStops.destination.features[0].properties.name
					: 'Wegpunkt B',
		}
		return (
			<div className='myRoutes--waypointItemWrapper'>
				<div
					className='myRoutes--waypointItem'
					onClick={() => {
						props.startInputFocused.current = false
						goToSearch()
					}}>
					<div className='myRoutes--waypointTitleWrapper'>
						<div className='myRoutes--waypointEnum'>{waypointB.enum}</div>
						<div className='myRoutes--waypointTitle'>{waypointB.name}</div>
					</div>
				</div>
				<div
					onClick={() => {
						handleDeleteWaypoint(props.myRouteStops.viaStops.features.length + 1)
					}}
					className='myRoutes--waypointDeleteIcon'
					style={{ width: mouseOverWaypointInputs ? 24 : 0 }}>
					<svg
						fill='#afafaf'
						viewBox='0 0 24 24'
						height={mouseOverWaypointInputs ? 16 : 0}
						width={mouseOverWaypointInputs ? 16 : 0}>
						<path fill='none' d='M0 0h24v24H0z'></path>
						<path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z'></path>
					</svg>
				</div>
			</div>
		)
	}

	const renderMiddleWaypoints = () => {
		return props.myRouteStops.viaStops.features.map((feature, index) => {
			const waypoint = {
				enum: index + 1,
				name: feature.properties?.name ? feature.properties.name : 'Wegpunkt #' + (index + 1),
			}
			return (
				<div key={waypoint.enum} className='myRoutes--waypointItemWrapper'>
					<div
						className='myRoutes--waypointItem'
						onClick={() => {
							props.startInputFocused.current = false
							goToSearch()
						}}>
						<div className='myRoutes--waypointTitleWrapper'>
							<div className='myRoutes--waypointEnum'>{waypoint.enum}</div>
							<div className='myRoutes--waypointTitle'>{waypoint.name}</div>
						</div>
					</div>
					<div
						onClick={() => {
							handleDeleteWaypoint(index + 1)
						}}
						className='myRoutes--waypointDeleteIcon'
						style={{ width: mouseOverWaypointInputs ? 24 : 0 }}>
						<svg
							fill='#afafaf'
							viewBox='0 0 24 24'
							height={mouseOverWaypointInputs ? 16 : 0}
							width={mouseOverWaypointInputs ? 16 : 0}>
							<path fill='none' d='M0 0h24v24H0z'></path>
							<path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z'></path>
						</svg>
					</div>
				</div>
			)
		})
	}

	const renderWaypoints = () => {
		return (
			<>
				{renderStartWaypoint()}
				{renderMiddleWaypoints()}
				{renderDestinatonWaypoint()}
			</>
		)
	}

	const renderRouteDetails = () => {
		return (
			<>
				<div className='myRoutes--routingWrapper'>
					<div className='myRoutes--configItem'>
						<div>Art, Zweck</div>
						<select
							className='myRoutes--configSelect'
							value={routeType}
							onChange={evt => {
								setRouteType(evt.target.value)
							}}>
							{Object.entries(routeTypes).map(([key, value]) => (
								<Fragment key={key}>
									{['cafe_restaurant', 'public_transport_station'].includes(key) && <option disabled></option>}
									<option value={key}>{value}</option>
								</Fragment>
							))}
						</select>
					</div>
				</div>
				<div className='myRoutes--routingWrapper'>
					<div className='myRoutes--configItem'>
						<div>Bezeichnung</div>
						<select
							className='myRoutes--configSelect'
							defaultValue={props.selectedUserRoute ? '' : routeName}
							onChange={evt => {
								setRouteName(evt.target.value)
								setIsRouteNameInputVisible(!evt.target.value)
							}}>
							<option>Hinweg</option>
							<option>Rückweg</option>
							<option>Alternativer Hinweg</option>
							<option>Alternativer Rückweg</option>
							<option value=''>Andere</option>
						</select>
					</div>
					{isRouteNameInputVisible && (
						<input
							className='myRoutes--input'
							style={{ width: '100%' }}
							type='text'
							value={routeName}
							onChange={e => setRouteName(e.target.value)}
							placeholder='Gib deinen eigenen Routennamen ein'
						/>
					)}
				</div>
			</>
		)
	}

	const renderRouting = () => {
		return (
			<>
				<div className='myRoutes--routingWrapper'>
					<div
						className='myRoutes--waypointsWrapper'
						onMouseEnter={() => {
							setMouseOverWaypointInputs(true)
						}}
						onMouseLeave={() => {
							setMouseOverWaypointInputs(false)
						}}>
						{renderWaypoints()}
					</div>
					<IconButton className='myRoutes--iconButton' size='small' onClick={() => revertWaypoints()}>
						<SwapVertIcon fontSize='inherit' />
						<span className='myRoutes--iconButtonLabel'>Richtung ändern</span>
					</IconButton>
				</div>
				<div className='myRoute--saveRoutesWrapper'>
					<div className='myRoute--infoOnRouting'>
						Bevor du deine Route speicherst, versuche sie möglichst genau zu erstellen. Um zu starten, klicke auf die
						Karte, gib deine Adresse in der Suchleiste ein oder wähle einen bereits erstellten Ort aus.
					</div>
					<button
						disabled={!routeName.length || props.journeyFeatureCollection.features.length === 0}
						className='myRoutes--button'
						onClick={() => {
							props.onSaveRoute({
								name: routeName,
								type: routeType,
							})
						}}>
						{props.selectedUserRoute ? 'Route speichern' : 'Neue Route speichern'}
					</button>
				</div>
			</>
		)
	}

	const renderPlaceSearchOverview = () => {
		return (
			<div>
				{!props.placeSelected && (
					<div className='myRoutes--searchResultsTitle'>
						{!props.search.text.length ? 'Gespeicherte Orte' : 'Suchergebnisse'}
					</div>
				)}
				<PlaceSearchResults
					placeSelected={props.placeSelected}
					setPlaceSelected={props.setPlaceSelected}
					startInputFocused={props.startInputFocused}
					onPlaceClicked={props.onPlaceClicked}
					clearMySelectedPlace={props.clearMySelectedPlace}
					placeList={props.searchResults}
					onSavePlace={props.onSavePlace}
					myRouteStops={props.myRouteStops}
					onSetAsStartPoint={feature => {
						props.setSearch({ text: '' })
						props.setPlaceSelected(null)
						props.onSetAsStartPoint(feature)
						setPlaceSearchOverviewActive(false)
					}}
					onSetAsEndPoint={feature => {
						props.setSearch({ text: '' })
						props.setPlaceSelected(null)
						props.onSetAsEndPoint(feature)
						setPlaceSearchOverviewActive(false)
					}}
					onSetAsViaPoint={feature => {
						props.setSearch({ text: '' })
						props.setPlaceSelected(null)
						props.onSetAsViaPoint(feature)
						setPlaceSearchOverviewActive(false)
					}}
					isSavingUserRoute={props.isSavingUserRoute}
					setIsSavingUserRoute={props.setIsSavingUserRoute}
				/>
			</div>
		)
	}

	const renderCreateRoutes = () => {
		return (
			<>
				{!placeSearchOverviewActive && !props.placeSelected && renderRouteDetails()}
				{!props.placeSelected && (
					<div className='myRoutes--searchWrapper'>
						<div
							className='myRoutes--searchClose'
							style={{
								cursor: 'pointer',
								visibility: placeSearchOverviewActive ? 'visible' : 'hidden',
								width: placeSearchOverviewActive ? 24 : 0,
								marginRight: placeSearchOverviewActive ? 8 : 0,
							}}
							onClick={() => {
								setPlaceSearchOverviewActive(false)
								props.onSearchClose()
							}}>
							<svg
								stroke='currentColor'
								fill='currentColor'
								strokeWidth='0'
								viewBox='0 0 512 512'
								height='24px'
								width='24px'>
								<path d='M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z'></path>
							</svg>
						</div>
						<input
							onFocus={() => {
								props.onSearchFocus()
								props.setPlaceSelected(null)
							}}
							value={props.search.text}
							onChange={e => {
								props.setPlaceSelected(null)
								props.onSearchChange(e.target.value)
							}}
							onClick={() => setPlaceSearchOverviewActive(true)}
							style={{ width: '100%' }}
							type='text'
							placeholder='Nach Straßenname, Straßenummer suchen'
							className='myRoutes--input'
							ref={props.searchRef}
						/>
					</div>
				)}
				{(placeSearchOverviewActive || props.placeSelected) && renderPlaceSearchOverview()}
				{!placeSearchOverviewActive && !props.placeSelected && renderRouting()}
			</>
		)
	}

	return (
		<div className='myRoutes--wrapper'>
			<div style={routeChooserStyle}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<input
						style={{ margin: '0 4px 0 0' }}
						type='radio'
						id='officialRoutes'
						value='officialRoutes'
						onChange={event => event.target.checked && props.onSetRouteTypeChooser('officialRoutes')}
						checked={props.routeTypeChooser === 'officialRoutes' ? true : false}
					/>
					<label htmlFor='officialRoutes'>Offizielle Routen</label>
				</div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<input
						style={{ margin: '0 4px 0 0' }}
						type='radio'
						id='myRoutes'
						value='myRoutes'
						onChange={event => event && event.target.checked && props.onSetRouteTypeChooser('myRoutes')}
						checked={props.routeTypeChooser === 'myRoutes' ? true : false}
					/>
					<label htmlFor='myRoutes'>Meine Routen</label>
				</div>
			</div>
			{props.myRoutesOpened && props.routeTypeChooser === 'myRoutes' ? (
				<div style={popupStyle}>
					<div className={'myRoutes--TabWrapper'}>
						<div
							onClick={() => props.onSetSelectedTab('routeList')}
							className={props.selectedTab === 'routeList' ? 'left active' : 'left'}>
							Übersicht
						</div>
						<div
							onClick={() => props.onSetSelectedTab('createRoute')}
							className={props.selectedTab === 'createRoute' ? 'right active' : 'right'}>
							{props.selectedUserRoute ? 'Route editieren' : 'Route erstellen'}
						</div>
					</div>
					<div className='myRoutes--contentWrapper'>
						{props.selectedTab === 'routeList' && renderMyRoutes()}
						{props.selectedTab === 'createRoute' && renderCreateRoutes()}
					</div>
				</div>
			) : null}
		</div>
	)
}
