import mapboxgl from 'mapbox-gl'
import { nearestPointOnLine } from '@turf/nearest-point-on-line'
import chroma from 'chroma-js'
import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, Fragment } from 'react'
import MapGL, { Popup, GeolocateControl, Source, Layer, MapProvider } from 'react-map-gl'
import RouteEditorMenu from './RouteEditorMenu'
import MainMenu from './MainMenu'
import classifyEdges from '../api/classifyEdges'
import localStorageService from '../services/localStorageService'
import DesktopMenu from '../components/DesktopMenu'
import MyRoutes from './MyRoutes'
import {
	routeJourney,
	getPlaceFromAddress,
	getUserPlaces,
	saveUserPlace,
	saveUserRoute,
	getUserRoutes,
	deleteUserRoute,
	getPlace,
	updateUserRoute,
} from '../api'
import debounce from 'lodash.debounce'
import { distance } from '@turf/distance'
import { AVAILABLE_CITIES, getCityCode } from '../cities'
import { fitToFeatureBounds, generateImageURL } from '../utils'
import { MAP_LAYERS } from './mapLayers'
import { MAP_STYLES } from './mapStyles'
import { useSearchParams } from 'react-router-dom'
import { useAppContext } from '../App'
import { mapImages } from './mapImages'
import CitySelector from './CitySelector'

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default
const TOKEN = 'pk.eyJ1IjoiZGpyYSIsImEiOiJjamZmZ2RzamYyM2JyMzNwYWc1aThzdmloIn0.tuEuIrtp3DK0ALX2J1clEw'

const generateColors = numColors => {
	var colors = []

	for (var i = 0; i < numColors; i++) {
		var randomColor = chroma.random()
		var contrastRatio = chroma.contrast(randomColor, '#ffffff')

		// Adjust the contrast ratio threshold as needed (4.5 is recommended for text readability)
		while (contrastRatio < 4.5) {
			randomColor = chroma.random()
			contrastRatio = chroma.contrast(randomColor, '#ffffff')
		}

		// Convert the color to hex format and add to the array
		var hexColor = randomColor.hex()
		colors.push(hexColor)
	}

	return colors
}

const generateDarkerColor = color => {
	return chroma(color).darken(0.5).hex()
}

const PopupLayers = forwardRef(function PopupLayers(props, ref) {
	// Render popups and other fast changing data in a separate component so that state changes
	// do not cause a lot of expensive rerendering
	const [edgePopup, setEdgePopup] = React.useState(null)
	const [journeyTrackerPopup, setJourneyTrackerPopup] = useState(null)
	const [journeyTracker, setJourneyTracker] = React.useState({
		type: 'FeatureCollection',
		features: [],
	})
	const journeyTrackerRef = useRef(journeyTracker)

	useEffect(() => {
		journeyTrackerRef.current = journeyTracker
	}, [journeyTracker])

	useImperativeHandle(
		ref,
		() => ({
			setEdgePopup,
			journeyTrackerRef,
			setJourneyTracker,
			setJourneyTrackerPopup,
		}),
		[],
	)

	return (
		<React.Fragment>
			{props.routeMenuOpened && edgePopup && (
				<Popup
					latitude={edgePopup.latitude}
					longitude={edgePopup.longitude}
					closeButton={false}
					closeOnClick={false}
					offset={15}>
					<div style={{ fontSize: 10, color: '#888' }}>ogc_fid: {edgePopup.ogc_fid}</div>
					{edgePopup.route && <div style={{ fontSize: 10, color: '#888' }}>route: {edgePopup.route}</div>}
					{edgePopup.image_name && (
						<div style={{ marginTop: 12 }}>
							<img src={edgePopup.image_name} />
						</div>
					)}
				</Popup>
			)}
			{props.isRouting && (
				<React.Fragment>
					{journeyTrackerPopup && (
						<Popup
							latitude={journeyTrackerPopup.latitude}
							longitude={journeyTrackerPopup.longitude}
							closeButton={false}
							closeOnClick={false}
							offset={15}
							className='journey-tracker-popup-info'>
							<div style={{ fontSize: 12, color: '#444', fontFamily: 'sans-serif' }}>
								Ziehen, um die Route zu ändern
							</div>
							<div style={{ marginTop: 8, fontSize: 10, color: '#999', fontFamily: 'sans-serif' }}>
								{journeyTrackerPopup.content}
							</div>
						</Popup>
					)}
					<Source id='journey-tracker' type='geojson' data={journeyTracker}>
						<Layer {...MAP_LAYERS.journeyTrackerLayer} />
					</Source>
				</React.Fragment>
			)}
		</React.Fragment>
	)
})

const Map = props => {
	const { isUserLoggedIn } = useAppContext()
	const mapRef = useRef()
	const mapImagesRef = useRef(
		Object.fromEntries(mapImages.map(image => [image.name, { url: image.url, isLoaded: false, data: null }])),
	)
	const popupLayersRef = useRef()
	const [geoLocateControlStyle, setGeoLocateStyle] = useState({
		right: 6,
		top: 20,
		position: 'fixed',
	})
	const [myRoutesOpened, setMyRoutesOpened] = useState(false)
	const [isJourneyLoading, setIsJourneyLoading] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const [routeTypeChooser, setRouteTypeChooser] = React.useState('officialRoutes')
	const [routeConnections, setRouteConnections] = React.useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [myRouteStops, setMyRouteStops] = useState({
		start: {
			type: 'FeatureCollection',
			features: [],
		},
		destination: {
			type: 'FeatureCollection',
			features: [],
		},
		viaStops: {
			type: 'FeatureCollection',
			features: [],
		},
	})
	const [selectedUserRoute, setSelectedUserRoute] = React.useState(null)
	const newUserRouteRef = useRef(null)
	const [isSavingUserRoute, setIsSavingUserRoute] = useState(false)
	const isRoutingDisabled = useRef(false)
	const searchRef = useRef()
	const startInputFocused = useRef(false)
	const [mySelectedPlace, setMySelectedPlace] = useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [userPlaces, setUserPlaces] = useState({ type: 'FeatureCollection', features: [] })
	const [search, setSearch] = useState({ text: '' })
	const [searchResults, setSearchResults] = useState({ type: 'FeatureCollection', features: [] })
	const [placeSelected, setPlaceSelected] = useState(null)
	const [userRoutes, setUserRoutes] = useState([])
	const journeyLine = useRef({ type: 'Feature', properties: {}, geometry: {} })
	const [journeyFeatureCollection, setJourneyFeatureCollection] = useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [mapStyle, setMapStyle] = useState(MAP_STYLES.DEFAULT)
	const [selectedTab, setSelectedTab] = useState('routeList')
	const [journeyTrackerDragging, setJourneyTrackerDragging] = useState({
		location: null,
		coordinates: null,
		dragging: false,
		existingPoint: null,
	})

	const { city } = getCityCode()
	const cityProperties = AVAILABLE_CITIES[city]
	const initialViewState = {
		latitude: cityProperties.latitude,
		longitude: cityProperties.longitude,
		zoom: cityProperties.zoom,
	}

	useEffect(() => {
		// Clear state when login state changes
		journeyStateCleanup(true)
		setUserRoutes([])
		setUserPlaces({ type: 'FeatureCollection', features: [] })

		if (
			isUserLoggedIn &&
			searchParams.get('showMyRoutes') &&
			searchParams.get('placeName') &&
			searchParams.get('placeLonLat')
		) {
			// Show my routes when logged in with specific query parameters
			setRouteTypeChooser('myRoutes')
			setMyRoutesOpened(true)
		} else if (!isUserLoggedIn) {
			// Show official routes when logged out
			setRouteTypeChooser('officialRoutes')
			setMyRoutesOpened(false)
		}
	}, [isUserLoggedIn])

	useEffect(() => {
		if (isUserLoggedIn && routeTypeChooser === 'myRoutes') {
			setMapStyle(MAP_STYLES.ROUTING)
			const userId = localStorageService.getUser()?.user_id
			getUserPlaces({ userId }).then(places => {
				setUserPlaces(places)
			})

			getUserRoutes().then(routes => {
				routes.forEach(route => {
					route.outlineColor = generateDarkerColor(route.color)
					route.selected = true
				})

				setUserRoutes(routes)
			})

			if (searchParams.get('placeName') && searchParams.get('placeLonLat')) {
				setSelectedTab('createRoute')
				setMyRouteStops({
					destination: {
						type: 'FeatureCollection',
						features: [
							{
								type: 'Feature',
								geometry: {
									type: 'Point',
									coordinates: searchParams.get('placeLonLat').split(',').map(parseFloat),
								},
								properties: {
									name: searchParams.get('placeName'),
									enum: 'B',
								},
							},
						],
					},
					start: {
						type: 'FeatureCollection',
						features: [],
					},
					viaStops: {
						type: 'FeatureCollection',
						features: [],
					},
				})
			}
		} else {
			setMapStyle(MAP_STYLES.DEFAULT)
		}
	}, [routeTypeChooser, isUserLoggedIn])

	const getClassificationColor = classification => {
		let votes = 0
		let votesNumber = 0

		if (classification.safety && classification.attractiveness && classification.conflict) {
			const detailedVoting = (classification.safety + classification.attractiveness + classification.conflict) / 3
			votes += detailedVoting
			votesNumber++
		}

		// only calculate real values
		if (classification.globalVote) {
			votes += classification.globalVote
			votesNumber++
		}

		const value = votes / votesNumber

		if (votesNumber === 0) {
			return '#B3ACBD'
		}

		if (value < 1.5) {
			return '#ec6d6e'
		} else if (value >= 1.5 && value < 2.5) {
			return '#f3b442'
		} else if (value >= 2.5 && value < 3.5) {
			return '#96b63c'
		} else if (value >= 3.5) {
			return '#59864e'
		}
	}

	const handleClassify = classification => {
		classifyEdges({
			type: 'FeatureCollection',
			features: selectedFeatures.features,
			properties: {
				user_id: localStorageService.getUser()?.user_id,
				...classification,
			},
		})
			.then(data => data)
			.then(() => {
				// in user classification view only thing that changed are edges sent to be reclassified.
				const newClassifiedFeaturesIds = []
				const newClassifiedFeatures = selectedFeatures.features.map(feature => {
					feature.properties.color = getClassificationColor(classification)
					newClassifiedFeaturesIds.push(feature.properties.ogc_fid)
					return feature
				})

				// filter old classifications
				const filteredClassifiedFeatures = classifiedFeatures.features.filter(feature => {
					return !newClassifiedFeaturesIds.includes(feature.properties.ogc_fid)
				})

				setClassifiedFeatures({
					type: 'FeatureCollection',
					features: [...filteredClassifiedFeatures, ...newClassifiedFeatures],
				})

				setTimeout(() => {
					setSelectedFeatures({
						type: 'FeatureCollection',
						features: [],
					})
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	// Allow highlighting edges by clicking them in RouteEditorMenu
	const [clickedOgcFid, setClickedOgcFid] = useState(null)
	const [highlightedOgcFid, setHighlightedOgcFid] = useState(null)
	const [highlightedFeatures, setHighlightedFeatures] = useState({ type: 'FeatureCollection', properties: {}, features: [] })
	useEffect(() => {
		let timeoutId
		const feature = highlightedOgcFid && props.data.features.find(feature => feature.properties.ogc_fid === highlightedOgcFid)
		if (feature) {
			// Update highlighted feature and show it
			setHighlightedFeatures({ type: 'FeatureCollection', properties: {}, features: [feature] })
			mapRef.current?.getMap().setPaintProperty('feature-highlighted-layer', 'line-opacity', 1)

			// Hide highlight after 5 seconds
			timeoutId = setTimeout(() => {
				mapRef.current?.getMap().setPaintProperty('feature-highlighted-layer', 'line-opacity', 0)
				setHighlightedOgcFid(null)
			}, 5000)
		}

		// Clear hiding timeout if highlightedOgcFid changes or component unmounts
		return () => {
			timeoutId && clearTimeout(timeoutId)
		}
	}, [highlightedOgcFid])

	const handleClassificationChange = classification => {
		const classificationColor = getClassificationColor(classification)

		const features = selectedFeatures.features.map(feature => {
			return { ...feature, properties: { ...feature.properties, color: classificationColor } }
		})

		setSelectedFeatures({
			type: 'FeatureCollection',
			features,
		})
	}
	const [selectedFeatures, setSelectedFeatures] = useState({ type: 'FeatureCollection', properties: {}, features: [] })
	const unselectableEdgeRef = useRef(null)
	const [classifiedFeatures, setClassifiedFeatures] = useState({
		type: 'FeatureCollection',
		properties: {},
		features: (() => {
			return props.classifiedData.features.map(f => {
				f.properties.color = getClassificationColor(f.properties)
				return f
			})
		})(),
	})
	const [routeMenuOpened, setRouteMenuOpened] = useState(false)
	const [isRouteEditorRouting, setIsRouteEditorRouting] = useState(false)
	const [isEditingRouteStartPoint, setIsEditingRouteStartPoint] = useState(false)
	const [activeMenu, setActiveMenu] = useState({ title: 'classify' })
	const [veloData, setVeloData] = React.useState({
		type: 'FeatureCollection',
		features: props.data.features.filter(f => f.properties.route_id && f.properties.route_visible === 1),
	})
	const [adminOption, setAdminOption] = React.useState('route_builder')
	const [veloCityData, setVeloCityData] = React.useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [selectedRouteData, setSelectedRouteData] = useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [selectedOverlay, setSelectedOverlay] = useState({
		type: 'FeatureCollection',
		features: [],
	})

	const [routeStartPointFeature, setRouteStartPointFeature] = useState({
		type: 'FeatureCollection',
		features: [],
	})

	const isRouting = (myRoutesOpened && selectedTab === 'createRoute') || (routeMenuOpened && isRouteEditorRouting)

	const removeStart = () => {
		journeyStateCleanup(true)
	}

	const journeyStateCleanup = includingStops => {
		setSelectedUserRoute(null)
		setIsSavingUserRoute(false)
		clearMySelectedPlace()
		setJourneyFeatureCollection({
			type: 'FeatureCollection',
			features: [],
		})
		setRouteConnections({
			type: 'FeatureCollection',
			features: [],
		})
		popupLayersRef.current?.setJourneyTracker({ type: 'FeatureCollection', features: [] })
		popupLayersRef.current?.setJourneyTrackerPopup(null)
		if (includingStops) {
			setMyRouteStops({
				start: {
					type: 'FeatureCollection',
					features: [],
				},
				destination: {
					type: 'FeatureCollection',
					features: [],
				},
				viaStops: {
					type: 'FeatureCollection',
					features: [],
				},
			})

			setSearchResults({ type: 'FeatureCollection', features: [] })
			setSearch({ text: '', active: false })
		}
	}

	function getCenterFromBounds(bounds) {
		const centerLng = (bounds[0][0] + bounds[1][0]) / 2
		const centerLat = (bounds[0][1] + bounds[1][1]) / 2
		return [centerLng, centerLat]
	}

	function getBoundsFromCenter(center) {
		const zoom = 14
		const [longitude, latitude] = center
		const halfWidth = 360 / Math.pow(2, zoom) / 2
		const halfHeight = 180 / Math.pow(2, zoom) / 2
		const bounds = {
			west: longitude - halfWidth,
			east: longitude + halfWidth,
			south: latitude - halfHeight,
			north: latitude + halfHeight,
		}

		return [
			[bounds.east, bounds.south],
			[bounds.west, bounds.north],
		]
	}

	const imagesPinsFeatures = React.useMemo(
		() => ({
			type: 'FeatureCollection',
			features:
				props.images &&
				props.images.features.map(feature => {
					return {
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [feature.properties.projected_lon, feature.properties.projected_lat],
						},
						properties: { ...feature.properties },
					}
				}),
		}),
		[props.images],
	)

	const imagesPinsOriginFeatures = React.useMemo(
		() => ({
			type: 'FeatureCollection',
			features:
				props.images &&
				props.images.features.map(feature => {
					return {
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [feature.properties.exif_lon, feature.properties.exif_lat],
						},
						properties: { ...feature.properties },
					}
				}),
		}),
		[props.images],
	)

	const pinConnectionsFeatures = React.useMemo(
		() => ({
			type: 'FeatureCollection',
			features:
				props.images &&
				props.images.features.map(feature => {
					return {
						type: 'Feature',
						geometry: {
							type: 'LineString',
							coordinates: [
								[feature.properties.exif_lon, feature.properties.exif_lat],
								[feature.properties.projected_lon, feature.properties.projected_lat],
							],
						},
						properties: { ...feature.properties },
					}
				}),
		}),
		[props.images],
	)

	useEffect(() => {
		// Force repaint with setData if selectedRouteData or feature properties change (isDeleted)
		// Otherwise dynamic styling is not triggered
		mapRef.current?.getMap().getSource('selectedRoute')?.setData(selectedRouteData)
	}, [selectedRouteData])

	let networkData = !routeMenuOpened ? veloData : props.data
	if (adminOption === 'images') {
		networkData = { type: 'FeatureCollection', features: [] }
	}

	const handleDeleteWaypoint = index => {
		if (index === 0 && !myRouteStops.destination.features.length) {
			// clean up all stops
			journeyStateCleanup(true)
			return
		}

		if (index === 1 && !myRouteStops.viaStops.features.length) {
			// remove destination
			setMyRouteStops({
				...myRouteStops,
				destination: {
					type: 'FeatureCollection',
					features: [],
				},
			})

			journeyStateCleanup(false)
			return
		}

		if (index === 0) {
			// if there are waypoint, set start as first waypoint and remove first waypoint
			if (myRouteStops.viaStops.features.length) {
				setMyRouteStops({
					...myRouteStops,
					start: {
						type: 'FeatureCollection',
						features: [myRouteStops.viaStops.features[0]].map((feature, i) => {
							return {
								...feature,
								properties: {
									...feature.properties,
									enum: 'A',
									name: feature.properties.name,
								},
							}
						}),
					},
					viaStops: {
						type: 'FeatureCollection',
						features: myRouteStops.viaStops.features.slice(1).map((feature, i) => {
							return {
								...feature,
								properties: {
									...feature.properties,
									enum: i + 1,
									name: feature.properties.name || `Wegpunkt #${i + 1}`,
								},
							}
						}),
					},
				})

				return
			}

			// if there is destination set it as start and remove destination
			if (myRouteStops.destination.features.length) {
				setMyRouteStops({
					...myRouteStops,
					start: {
						type: 'FeatureCollection',
						features: [myRouteStops.destination.features[0]].map((feature, i) => {
							return {
								...feature,
								properties: {
									...feature.properties,
									enum: 'A',
									name: feature.properties.name,
								},
							}
						}),
					},
					destination: {
						type: 'FeatureCollection',
						features: [],
					},
				})

				journeyStateCleanup(false)
				return
			}
		}

		setMyRouteStops({
			...myRouteStops,
			viaStops: {
				type: 'FeatureCollection',
				features: myRouteStops.viaStops.features
					.filter((_, i) => i !== index - 1)
					.map((feature, i) => {
						return {
							...feature,
							properties: {
								...feature.properties,
								enum: i + 1,
								name: feature.properties.name || `Wegpunkt #${i + 1}`,
							},
						}
					}),
			},
		})
	}

	const clearMySelectedPlace = () => {
		setMySelectedPlace({
			type: 'FeatureCollection',
			features: [],
		})
	}

	const generateLineStringFromFeatureCollection = featureCollection => {
		// Determine correct LineString direction for undirected networks
		featureCollection.features.forEach((feature, index) => {
			if (index === 0) {
				// Compare against next feature when processing first feature
				const targetFeature = featureCollection.features[index + 1]
				// Reverse coordinates if feature end point is not start or end point of next feature
				if (
					targetFeature &&
					![
						JSON.stringify(targetFeature.geometry.coordinates[0]),
						JSON.stringify(targetFeature.geometry.coordinates.at(-1)),
					].includes(JSON.stringify(feature.geometry.coordinates.at(-1)))
				) {
					feature.geometry.coordinates.reverse()
				}
			} else {
				// Compare against previous feature which is already in correct direction
				const targetFeature = featureCollection.features[index - 1]
				// Reverse coordinates if feature start point is not end point of previous feature
				if (
					targetFeature &&
					JSON.stringify(feature.geometry.coordinates[0]) !== JSON.stringify(targetFeature.geometry.coordinates.at(-1))
				) {
					feature.geometry.coordinates.reverse()
				}
			}
		})

		const newFeatureCollection = { type: 'FeatureCollection', features: [...featureCollection.features] }
		const coordinates = newFeatureCollection.features.reduce((acc, feature) => [...acc, ...feature.geometry.coordinates], [])

		return {
			type: 'Feature',
			properties: {},
			geometry: {
				type: 'LineString',
				coordinates: coordinates,
			},
		}
	}

	const handleSetAsStartPoint = feature => {
		setMyRouteStops({
			...myRouteStops,
			start: {
				type: 'FeatureCollection',
				features: [{ ...feature, properties: { ...feature.properties, enum: 'A' } }],
			},
		})
		clearMySelectedPlace()
	}

	const handleSetAsEndPoint = feature => {
		clearMySelectedPlace()
		// current endpoint should go to via stops and new endpoint should be set
		if (myRouteStops.destination.features.length) {
			setMyRouteStops({
				...myRouteStops,
				viaStops: {
					type: 'FeatureCollection',
					features: [
						...myRouteStops.viaStops.features,
						{
							...myRouteStops.destination.features[0],
							properties: {
								...myRouteStops.destination.features[0].properties,
								enum: myRouteStops.viaStops.features.length + 1,
							},
						},
					],
				},
				destination: {
					type: 'FeatureCollection',
					features: [{ ...feature, properties: { ...feature.properties, enum: 'B' } }],
				},
			})

			return
		}
		// in case there is no destination set
		setMyRouteStops({
			...myRouteStops,
			destination: {
				type: 'FeatureCollection',
				features: [{ ...feature, properties: { ...feature.properties, enum: 'B' } }],
			},
		})
	}

	const handleSetAsViaPoint = feature => {
		setMyRouteStops({
			...myRouteStops,
			viaStops: {
				type: 'FeatureCollection',
				features: [
					...myRouteStops.viaStops.features,
					{ ...feature, properties: { ...feature.properties, enum: myRouteStops.viaStops.features.length + 1 } },
				],
			},
		})
		clearMySelectedPlace()
	}

	const handleJourneyRouting = async (startFeatureCollection, destinationFeatureCollection) => {
		// if there are not start and destination then return
		if (!startFeatureCollection.features.length || !destinationFeatureCollection.features.length) {
			return
		}

		// in case start and destination are the same
		if (
			startFeatureCollection.features[0].geometry.coordinates[0] ===
				destinationFeatureCollection.features[0].geometry.coordinates[0] &&
			startFeatureCollection.features[0].geometry.coordinates[1] ===
				destinationFeatureCollection.features[0].geometry.coordinates[1]
		) {
			return
		}

		const start = startFeatureCollection.features[0]
		const destination = destinationFeatureCollection.features[0]
		setIsJourneyLoading(true)
		const journey = await routeJourney(
			{ start, destination, stops: myRouteStops.viaStops.features },
			routeMenuOpened && isRouteEditorRouting,
		)
		setIsJourneyLoading(false)

		const edgeFeatureCollection = {
			type: 'FeatureCollection',
			features: journey.features.filter(feature => feature.geometry.type === 'LineString'),
		}

		setJourneyFeatureCollection({
			type: 'FeatureCollection',
			features: edgeFeatureCollection.features.map(feature => {
				return {
					...feature,
					properties: {
						...feature.properties,
						color: '#3874ff',
					},
				}
			}),
		})

		journeyLine.current = generateLineStringFromFeatureCollection(edgeFeatureCollection)

		const connections = []
		for (const stop of [
			...myRouteStops.start.features,
			...myRouteStops.viaStops.features,
			...myRouteStops.destination.features,
		]) {
			// Update stop properties (node, edges) for subsequent routing calls
			const journeyStop = journey.features.find(feature => feature.properties.enum === stop.properties.enum)
			stop.properties = journeyStop.properties

			if (myRouteStops.viaStops.features.includes(stop)) {
				// Update myRouteStops viaStops so that all via stops are on a new journey line
				// unless viaStop is a user place, in which case we don't want to snap but draw a connection line
				const projectedStop = nearestPointOnLine(journeyLine.current, stop)
				if (stop.properties.id) {
					// Stop is a user place
					connections.push({
						type: 'Feature',
						geometry: {
							type: 'LineString',
							coordinates: [stop.geometry.coordinates, projectedStop.geometry.coordinates],
						},
					})
				} else {
					// Stop is not a user place
					stop.geometry.coordinates = projectedStop.geometry.coordinates
				}
			} else {
				// Start or destination
				connections.push({
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: [
							stop.geometry.coordinates,
							// Draw connection to journey start or end depending which one this stop is
							journeyLine.current.geometry.coordinates.at(myRouteStops.start.features.includes(stop) ? 0 : -1),
						],
					},
				})
			}
		}

		setRouteConnections({ type: 'FeatureCollection', features: connections })

		// Disable routing for this update of stops, we actually do not want to route again, just update the stops
		isRoutingDisabled.current = true
		setMyRouteStops({
			start: { ...myRouteStops.start },
			destination: { ...myRouteStops.destination },
			viaStops: { ...myRouteStops.viaStops },
		})

		// calculate new bounds
		if (!journeyFeatureCollection.features.length) {
			fitToFeatureBounds(mapRef.current, [journeyLine.current])
		}
	}

	const deboundedJourneyTrackerFreeMove = debounce(evt => {
		if (journeyTrackerDragging.dragging) {
			popupLayersRef.current?.setJourneyTracker({
				type: 'FeatureCollection',
				features: [
					{
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [evt.lngLat.lng, evt.lngLat.lat],
						},
					},
				],
			})
		}
	}, 4)

	const debouncedJourneyTrackerMove = debounce(closestFeature => {
		if (!journeyTrackerDragging.dragging && isRouting) {
			if (closestFeature?.layer.id === MAP_LAYERS.journeyLayer.id) {
				// Closest feature is the journey line so we can show the tracker
				popupLayersRef.current?.setJourneyTracker({
					type: 'FeatureCollection',
					features: [closestFeature.closestPoint],
				})

				popupLayersRef.current?.setJourneyTrackerPopup({
					longitude: closestFeature.closestPoint.geometry.coordinates[0],
					latitude: closestFeature.closestPoint.geometry.coordinates[1],
					content: getJourneyTrackerPopupText(closestFeature),
				})
			} else {
				// Clear journey tracker if journey line is not the closest feature
				popupLayersRef.current?.setJourneyTracker({ type: 'FeatureCollection', features: [] })
				popupLayersRef.current?.setJourneyTrackerPopup(null)
			}
		}
	}, 4)

	const getJourneyTrackerPopupText = feature => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div>{feature.properties.name}</div>
				<div>Road {feature.properties.highway}</div>
			</div>
		)
	}

	useEffect(() => {
		// Force repaint with setData if userPlaces or feature properties change (enum)
		// Otherwise dynamic styling is not triggered
		mapRef.current?.getMap().getSource(MAP_LAYERS.userPlacesLayer.source)?.setData(userPlaces)
	}, [userPlaces])

	const updateUserPlaceText = () => {
		const stops = [...myRouteStops.start.features, ...myRouteStops.viaStops.features, ...myRouteStops.destination.features]
		setUserPlaces({
			...userPlaces,
			features: userPlaces.features.map(userPlace => ({
				...userPlace,
				properties: {
					...userPlace.properties,
					// Set user place enum property if it is one of the stops
					enum: stops.find(feature => feature.properties.id === userPlace.properties.id)?.properties?.enum ?? null,
				},
			})),
		})
	}

	useEffect(() => {
		if (isRoutingDisabled.current) {
			isRoutingDisabled.current = false
		} else {
			// Trigger journey routing when stops change, unless it was explicitly disabled
			handleJourneyRouting(myRouteStops.start, myRouteStops.destination)
		}

		// Update user place labels if stops are user places
		updateUserPlaceText()

		// Force repaint with setData if myRouteStops or feature properties change (id, enum)
		// Otherwise dynamic styling is not triggered
		mapRef.current?.getSource(MAP_LAYERS.myRoutesRoutingStops.source)?.setData({
			type: 'FeatureCollection',
			features: [...myRouteStops.start.features, ...myRouteStops.viaStops.features, ...myRouteStops.destination.features],
		})
	}, [myRouteStops.start, myRouteStops.destination, myRouteStops.viaStops])

	const updateMyRouteStops = async evt => {
		const existingPoint = journeyTrackerDragging.existingPoint
		const place = await getPlace({ lon: evt.lngLat.lng, lat: evt.lngLat.lat })

		let feature = {
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [evt.lngLat.lng, evt.lngLat.lat] },
			properties: {},
		}

		// Check if drag ended at a user place
		const closestFeature = getClosestFeature(evt)
		if (closestFeature?.layer.id === MAP_LAYERS.userPlacesLayer.id) {
			feature = userPlaces.features.find(feature => feature.properties.id === closestFeature.properties.id)
		}

		if (existingPoint) {
			switch (existingPoint.properties?.enum) {
				case 'A':
					feature.properties.enum = 'A'
					feature.properties.name = feature.properties.name ?? (place.data.r_node_name || 'Wegpunkt A')
					myRouteStops.start.features = [feature]
					break
				case 'B':
					feature.properties.enum = 'B'
					feature.properties.name = feature.properties.name ?? (place.data.r_node_name || 'Wegpunkt B')
					myRouteStops.destination.features = [feature]
					break
				default:
					// If existing point is a via stop, replace it with the new feature
					const stopIndex = myRouteStops.viaStops.features.findIndex(
						stop => stop.properties.enum === existingPoint.properties.enum,
					)
					feature.properties.enum = existingPoint.properties.enum
					feature.properties.name =
						feature.properties.name ?? (place.data.r_node_name || `Wegpunkt #${existingPoint.properties.enum}`)
					myRouteStops.viaStops.features.splice(stopIndex, 1, feature)
			}
		} else {
			// Determine correct index for the new waypoint, depending on where the drag started
			let newIndex
			const newLocation = nearestPointOnLine(journeyLine.current, journeyTrackerDragging.coordinates).properties.location
			myRouteStops.viaStops.features.forEach((stop, index) => {
				const location = nearestPointOnLine(journeyLine.current, stop).properties.location
				if (newLocation < location) {
					// New waypoint is located before this stop, increment enum
					stop.properties.enum += 1
					if (newIndex === undefined) {
						// Set index for the new waypoint if not set already
						newIndex = index
					}
				}
			})
			// If new waypoint was not before any other waypoint, it is the last one
			newIndex = newIndex ?? myRouteStops.viaStops.features.length
			feature.properties.enum = newIndex + 1
			feature.properties.name =
				feature.properties.name ?? (place.data.r_node_name || `Wegpunkt #${myRouteStops.viaStops.features.length + 1}`)
			// Insert new waypoint at the determined index
			myRouteStops.viaStops.features.splice(newIndex, 0, feature)
		}

		setMyRouteStops({
			start: { ...myRouteStops.start },
			destination: { ...myRouteStops.destination },
			viaStops: { ...myRouteStops.viaStops },
		})
	}

	useEffect(() => {
		// on user places update we should update search results
		const newSearchResults = {
			type: 'FeatureCollection',
			features: [...userPlaces.features],
		}
		setSearchResults(newSearchResults)
	}, [userPlaces])

	useEffect(() => {
		if (search.text) {
			getPlaceFromAddressThrottled(search)
		}
	}, [search])

	const getPlaceFromAddressThrottled = React.useCallback(
		debounce(value => {
			const { text } = value
			getPlaceFromAddress({
				address: text,
			}).then(data => {
				// add user places to search results
				const upFeatures = userPlaces.features.filter(feature => {
					return feature.properties.name.toLowerCase().startsWith(text.toLowerCase())
				})
				setSearchResults({
					type: 'FeatureCollection',
					features: [...upFeatures, ...data.data.features],
				})
			})
		}, 500),
		[],
	)

	const handleOnSearchChange = value => {
		setSearch({
			text: value,
		})
		if (value.length === 0) {
			setSearchResults({
				type: 'FeatureCollection',
				features: userPlaces.features,
			})
		}
	}

	const handlePlaceClicked = (feature, draw = true) => {
		setPlaceSelected(feature)
		const coordinates = feature.geometry.coordinates
		if (!feature.properties.idle_id && draw) {
			// Clicked place is not an existing user place, so display it on the map
			setMySelectedPlace({
				type: 'FeatureCollection',
				features: [feature],
			})
		}
		mapRef.current?.getMap().flyTo({ center: { lng: coordinates[0], lat: coordinates[1] }, zoom: 17 })
	}

	const handleOnSearchFocus = () => {
		// set placeSearchResults to user places
		if (userPlaces.features.length > 0 && !searchResults.features.length) {
			setSearchResults({
				type: 'FeatureCollection',
				features: userPlaces.features,
			})
		}
	}

	const handleOnSavePlace = async place => {
		const newPlace = (
			await saveUserPlace({
				userId: localStorageService.getUser().user_id,
				node: place.node,
			})
		).features[0]

		setSearch({ text: '' })

		// If place already existed, restore the possible enum value
		const previousPlace = userPlaces.features.find(userPlace => userPlace.properties.id === newPlace.properties.id)
		newPlace.properties.enum = previousPlace?.properties.enum

		// Update user place list
		setUserPlaces({
			type: 'FeatureCollection',
			features: [
				// If user place already existed, filter it out and add the updated version
				...userPlaces.features.filter(userPlace => userPlace.properties.id !== newPlace.properties.id),
				newPlace,
			],
		})

		// If new user place was existing stop, we want to update properties of the stop for rendering
		const stopFeature = [
			...myRouteStops.start.features,
			...myRouteStops.viaStops.features,
			...myRouteStops.destination.features,
		].find(feature => feature.properties.enum === place.enum)
		if (stopFeature) {
			stopFeature.properties = { ...stopFeature.properties, ...newPlace.properties, enum: stopFeature.properties.enum }
			// Disable routing for this update of stops, we actually do not want to route again, just update the stops
			isRoutingDisabled.current = true
			setMyRouteStops({
				start: { ...myRouteStops.start },
				destination: { ...myRouteStops.destination },
				viaStops: { ...myRouteStops.viaStops },
			})
		}

		// Update selected place object
		setPlaceSelected(newPlace)

		// Clear dot on map, marker is displayed for saved places
		clearMySelectedPlace()

		if (isSavingUserRoute) {
			// Trigger saving for the same route again if user was creating places to save the route
			handleSaveRoute(newUserRouteRef.current)
		}
	}

	const calcDistance = coordinates => {
		let dist = 0
		for (let i = 0; i < coordinates.length - 1; i++) {
			dist += distance(coordinates[i], coordinates[i + 1], { units: 'kilometers' })
		}

		// round to 2 decimal places
		return Math.round((dist + Number.EPSILON) * 100) / 100
	}

	const handleSaveRoute = async route => {
		// Update new user route referense so we can trigger save again
		newUserRouteRef.current = route

		if (journeyFeatureCollection.features.length === 0) {
			return
		}

		// Set saving state so that it can be used in place editor
		setIsSavingUserRoute(true)

		// Start and destination need to be saved as user places before route can be saved
		for (const feature of [...myRouteStops.start.features, ...myRouteStops.destination.features]) {
			if (!feature.properties.id) {
				// Feature is not a user place
				handlePlaceClicked(feature, false)
				return
			}
		}

		const lineStringFeature = {
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates: generateLineStringFromFeatureCollection(journeyFeatureCollection).geometry.coordinates,
			},
			properties: {
				type: 'section',
				// Also check edges property if editing an existing route
				edges: journeyFeatureCollection.features.flatMap(
					feature => feature.properties.edges ?? feature.properties.ogc_fid,
				),
			},
		}

		const startFeature = {
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: myRouteStops.start.features[0].geometry.coordinates,
			},
			properties: {
				type: 'start',
				name: myRouteStops.start.features[0].properties?.name,
				idle_id: myRouteStops.start.features[0].properties?.idle_id,
				posmoUserNodeId: myRouteStops.start.features[0].properties?.id,
			},
		}

		const destinationFeature = {
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: myRouteStops.destination.features[0].geometry.coordinates,
			},
			properties: {
				type: 'end',
				name: myRouteStops.destination.features[0].properties?.name,
				idle_id: myRouteStops.destination.features[0].properties?.idle_id,
				posmoUserNodeId: myRouteStops.destination.features[0].properties?.id,
			},
		}

		const waypointFeatures = myRouteStops.viaStops.features.map(feature => ({
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: feature.geometry.coordinates,
			},
			properties: {
				type: 'waypoint',
				name: feature.properties?.name,
				posmoUserNodeId: feature.properties?.id,
			},
		}))

		const routeColor = generateColors(1)[0]
		const newRoute = {
			name: route.name,
			type: route.type,
			color: routeColor,
			geojson: {
				type: 'FeatureCollection',
				features: [startFeature, lineStringFeature, destinationFeature, ...waypointFeatures],
			},
		}

		let savedRoute
		if (selectedUserRoute) {
			savedRoute = await updateUserRoute(selectedUserRoute.id, newRoute)
			// Replace previous route with the updated one
			userRoutes.splice(
				userRoutes.findIndex(userRoute => userRoute.id === savedRoute.id),
				1,
				savedRoute,
			)
		} else {
			savedRoute = await saveUserRoute(newRoute)
			userRoutes.push(savedRoute)
		}

		savedRoute.outlineColor = generateDarkerColor(savedRoute.color)
		savedRoute.selected = true
		setUserRoutes([...userRoutes])
		setSelectedTab('routeList')
		journeyStateCleanup(true)
	}

	const handleUserRouteClicked = route => {
		route.selected = !route.selected
		setUserRoutes([...userRoutes])
	}

	const handleEditUserRoute = route => {
		// Disable routing for this update of stops
		isRoutingDisabled.current = true
		setSelectedTab('createRoute')
		setSelectedUserRoute(route)

		const newJourneyFeatureCollection = {
			type: 'FeatureCollection',
			features: route.geojson.features.filter(feature => {
				if (feature.geometry.type === 'LineString') {
					feature.properties.color = '#3874ff'
					return true
				}
			}),
		}
		setJourneyFeatureCollection(newJourneyFeatureCollection)

		let waypointIndex = 1
		const newRouteStops = {
			start: {
				type: 'FeatureCollection',
				features: route.geojson.features.filter(feature => {
					if (feature.properties.type === 'start') {
						feature.properties.id = feature.properties.posmoUserNodeId
						feature.properties.enum = 'A'
						return true
					}
				}),
			},
			viaStops: {
				type: 'FeatureCollection',
				features: route.geojson.features.filter(feature => {
					if (feature.properties.type === 'waypoint') {
						feature.properties.id = feature.properties.posmoUserNodeId
						feature.properties.enum = waypointIndex
						waypointIndex++
						return true
					}
				}),
			},
			destination: {
				type: 'FeatureCollection',
				features: route.geojson.features.filter(feature => {
					if (feature.properties.type === 'end') {
						feature.properties.id = feature.properties.posmoUserNodeId
						feature.properties.enum = 'B'
						return true
					}
				}),
			},
		}
		setMyRouteStops(newRouteStops)

		journeyLine.current = generateLineStringFromFeatureCollection(newJourneyFeatureCollection)
		setRouteConnections({
			type: 'FeatureCollection',
			features: [
				...newRouteStops.start.features,
				...newRouteStops.destination.features,
				...newRouteStops.viaStops.features.filter(feature => !!feature.properties.id),
			].map(feature => {
				let coordinates
				if (feature.properties.enum === 'A') {
					coordinates = journeyLine.current.geometry.coordinates[0]
				} else if (feature.properties.enum === 'B') {
					coordinates = journeyLine.current.geometry.coordinates.at(-1)
				} else {
					coordinates = nearestPointOnLine(journeyLine.current, feature).geometry.coordinates
				}
				return {
					type: 'Feature',
					geometry: { type: 'LineString', coordinates: [feature.geometry.coordinates, coordinates] },
				}
			}),
		})

		fitToFeatureBounds(mapRef.current, route.geojson.features)
	}

	const handleDeleteUserRoute = async routeId => {
		await deleteUserRoute({ routeId })
		const newUserRoutes = userRoutes.filter(route => route.id !== routeId)
		setUserRoutes(newUserRoutes)
	}

	const renderUserRoutesStops = () => {
		const urStops = {
			type: 'FeatureCollection',
			features: [],
		}

		userRoutes
			.filter(route => {
				return route.selected
			})
			.forEach(route => {
				const start = route.geojson.features.find(feature => feature.properties.type === 'start')
				const destination = route.geojson.features.find(feature => feature.properties.type === 'end')
				urStops.features.push({ ...start, properties: { ...start.properties, enum: 'A' } })
				urStops.features.push({ ...destination, properties: { ...destination.properties, enum: 'B' } })
			})

		return urStops
	}

	const renderUserRoutesStopConnections = () => {
		const connections = { type: 'FeatureCollection', features: [] }
		userRoutes
			.filter(route => {
				return route.selected
			})
			.forEach(route => {
				const start = route.geojson.features.find(feature => feature.properties.type === 'start')
				const destination = route.geojson.features.find(feature => feature.properties.type === 'end')
				const lineString = route.geojson.features.find(feature => feature.geometry.type === 'LineString')

				const connectionAtoLineStart = {
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: [start.geometry.coordinates, lineString.geometry.coordinates[0]],
					},
				}

				const connectionBtoLineEnd = {
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: [
							destination.geometry.coordinates,
							lineString.geometry.coordinates[lineString.geometry.coordinates.length - 1],
						],
					},
				}

				connections.features.push(connectionAtoLineStart)
				connections.features.push(connectionBtoLineEnd)
			})

		return connections
	}

	const renderUserRoutes = () => {
		return {
			type: 'FeatureCollection',
			features: userRoutes
				.filter(route => {
					return route.selected
				})
				.map(route => {
					const start = route.geojson.features.find(feature => feature.properties.type === 'start')
					const destination = route.geojson.features.find(feature => feature.properties.type === 'end')
					const lineString = route.geojson.features.find(feature => feature.geometry.type === 'LineString')
					route.distance = calcDistance(lineString.geometry.coordinates)
					return {
						type: 'Feature',
						geometry: {
							type: 'LineString',
							coordinates: lineString.geometry.coordinates,
						},
						properties: {
							name: route.name,
							type: route.type,
							start: start.properties,
							destination: destination.properties,
							color: route.color,
							outlineColor: route.outlineColor,
							id: route.id,
						},
					}
				}),
		}
	}

	const getClosestFeature = evt => {
		// Select features close to the event coordinates
		// https://docs.mapbox.com/mapbox-gl-js/example/queryrenderedfeatures-around-point/
		const coordinates = [evt.lngLat.lng, evt.lngLat.lat]
		const bbox = [
			[evt.point.x - 5, evt.point.y - 5],
			[evt.point.x + 5, evt.point.y + 5],
		]
		const layers = []
		if (isRouting) {
			layers.push(MAP_LAYERS.journeyLayer.id, MAP_LAYERS.myRoutesRoutingStops.id)
		}
		if (isRouting && myRoutesOpened) {
			layers.push(MAP_LAYERS.userPlacesLayer.id)
		}
		if (routeMenuOpened && !isRouting) {
			layers.push(MAP_LAYERS.networkLayer.id, MAP_LAYERS.imagesPinsLayer.id, MAP_LAYERS.imagesOriginPinsLayer.id)
		}
		const features = mapRef.current
			?.queryRenderedFeatures(bbox, { layers })
			// Find closest point from the feature so that we can sort features by distance from event coordinates
			.map(feature => {
				let closestPoint
				if (feature.geometry.type === 'Point') {
					// Prefer point geometries if they are within query, so set distance to 0
					closestPoint = { ...feature, properties: { ...feature.properties, dist: 0 } }
				} else if (['LineString', 'MultiLineString'].includes(feature.geometry.type)) {
					closestPoint = nearestPointOnLine(feature.geometry, coordinates)
				} else {
					// Could not determine closest point for this geometry type, so use event coordinates
					closestPoint = { type: 'Feature', geometry: { type: 'Point', coordinates }, properties: {} }
				}

				if (
					selectedRouteData.features.find(
						routeFeature => routeFeature.properties.ogc_fid === feature.properties.ogc_fid,
					)
				) {
					// If feature is one of selected route features, set distance to 0 so that it can be highlighted
					// instead of selecting a overlapping edge at the same location
					closestPoint.properties.dist = 0
				}

				return {
					...feature,
					closestPoint,
				}
			})

		// Sort features by distance from event coordinates
		const selectedFeatureIds = selectedFeatures.features.flatMap(feature => feature.properties.ogc_fid)
		features.sort((a, b) => {
			const distanceDifference = a.closestPoint.properties.dist - b.closestPoint.properties.dist
			if (Math.abs(distanceDifference) < 1e-6) {
				// If distance difference is almost equal, prefer clicking on an unselected feature
				// so that it replaces the overlapping feature already selected in a directional network
				return selectedFeatureIds.indexOf(a.properties.ogc_fid) - selectedFeatureIds.indexOf(b.properties.ogc_fid)
			} else {
				return distanceDifference
			}
		})

		const closestFeature = features[0]
		if (closestFeature) {
			// Convert 'null' property values to null
			// https://github.com/mapbox/mapbox-gl-js/issues/8497
			for (const [propertyKey, propertyValue] of Object.entries(closestFeature.properties)) {
				closestFeature.properties[propertyKey] = propertyValue === 'null' ? null : propertyValue
			}
		}
		return closestFeature
	}

	return (
		<MapProvider>
			{/* <CitySelector /> */}
			{isUserLoggedIn && searchParams.get('showMyRoutes') && (
				<MyRoutes
					startInputFocused={startInputFocused}
					searchRef={searchRef}
					selectedTab={selectedTab}
					search={search}
					searchResults={searchResults}
					myRouteStops={myRouteStops}
					setMyRouteStops={setMyRouteStops}
					userRoutes={userRoutes}
					journeyFeatureCollection={journeyFeatureCollection}
					setSearch={setSearch}
					placeSelected={placeSelected}
					setPlaceSelected={setPlaceSelected}
					clearMySelectedPlace={clearMySelectedPlace}
					onEditRoute={handleEditUserRoute}
					onDeleteRoute={handleDeleteUserRoute}
					onDeleteWaypoint={handleDeleteWaypoint}
					onUserRouteClicked={handleUserRouteClicked}
					onSetSelectedTab={tab => {
						if (tab !== selectedTab) {
							journeyStateCleanup(true)
							setSelectedTab(tab)
						}
					}}
					onSetAsStartPoint={handleSetAsStartPoint}
					onSetAsEndPoint={handleSetAsEndPoint}
					onSetAsViaPoint={handleSetAsViaPoint}
					onSearchFocus={handleOnSearchFocus}
					onPlaceClicked={handlePlaceClicked}
					onSearchChange={handleOnSearchChange}
					onSaveRoute={handleSaveRoute}
					onSearchClose={() => {
						setSearch({
							text: '',
							idle_id: null,
						})
						setSearchResults({
							type: 'FeatureCollection',
							features: userPlaces.features,
						})
						clearMySelectedPlace()
					}}
					onSavePlace={handleOnSavePlace}
					myRoutesOpened={myRoutesOpened}
					routeTypeChooser={routeTypeChooser}
					onSetRouteTypeChooser={choice => {
						setRouteTypeChooser(choice)
						if (choice === 'officialRoutes') {
							journeyStateCleanup(true)
							setMyRoutesOpened(false)
						} else {
							setMyRoutesOpened(true)
						}
					}}
					removeStart={removeStart}
					isSavingUserRoute={isSavingUserRoute}
					setIsSavingUserRoute={setIsSavingUserRoute}
					selectedUserRoute={selectedUserRoute}
					setSelectedUserRoute={setSelectedUserRoute}
				/>
			)}
			{isUserLoggedIn && (
				<RouteEditorMenu
					city={props.city}
					setSelectedOverlay={setSelectedOverlay}
					adminOption={adminOption}
					setAdminOption={setAdminOption}
					selectedRouteData={selectedRouteData}
					setSelectedRouteData={setSelectedRouteData}
					updateData={props.updateData}
					veloCityData={veloCityData}
					setVeloCityData={setVeloCityData}
					routeMenuOpened={routeMenuOpened}
					setRouteMenuOpened={setRouteMenuOpened}
					data={props.data}
					setSelectedFeatures={setSelectedFeatures}
					unselectableEdgeRef={unselectableEdgeRef}
					selectedFeatures={selectedFeatures.features}
					isEditingRouteStartPoint={isEditingRouteStartPoint}
					setIsEditingRouteStartPoint={setIsEditingRouteStartPoint}
					routeStartPointFeature={routeStartPointFeature}
					setRouteStartPointFeature={setRouteStartPointFeature}
					clickedOgcFid={clickedOgcFid}
					setClickedOgcFid={setClickedOgcFid}
					setHighlightedOgcFid={setHighlightedOgcFid}
					isRouteEditorRouting={isRouteEditorRouting}
					setIsRouteEditorRouting={setIsRouteEditorRouting}
					journeyFeatureCollection={journeyFeatureCollection}
					myRouteStops={myRouteStops}
					handleDeleteWaypoint={handleDeleteWaypoint}
					journeyStateCleanup={journeyStateCleanup}
					mapStyle={mapStyle}
					setMapStyle={setMapStyle}
				/>
			)}
			{!routeMenuOpened && (
				<MainMenu
					onClassify={handleClassify}
					setGeoLocateStyle={setGeoLocateStyle}
					onClassificationChange={handleClassificationChange}
					active={activeMenu}
					data={selectedFeatures}
				/>
			)}
			<MapGL
				id='map'
				ref={mapRef}
				initialViewState={initialViewState}
				mapboxAccessToken={TOKEN}
				style={{ width: '100vw', height: '100vh' }}
				mapStyle={mapStyle}
				scrollZoom={true}
				dragPan={!journeyTrackerDragging.dragging}
				onLoad={() => {
					if (searchParams.get('placeName') && searchParams.get('placeLonLat')) {
						// Focus on predefined place
						const coordinates = searchParams.get('placeLonLat').split(',').map(parseFloat)
						mapRef.current?.getMap().flyTo({ center: { lng: coordinates[0], lat: coordinates[1] }, zoom: 17 })
					}
				}}
				onStyleData={async () => {
					// Add images that can be used as icons in map
					// Use ref to prevent loading images multiple times while changing styles
					Object.entries(mapImagesRef.current).forEach(([imageKey, imageValue]) => {
						if (!imageValue.isLoaded) {
							mapImagesRef.current[imageKey].isLoaded = true
							// Load image if it has not been loaded yet
							mapRef.current?.getMap().loadImage(imageValue.url, (error, image) => {
								if (error) {
									throw error
								}
								// Update ref data
								mapImagesRef.current[imageKey].data = image
								if (!mapRef.current?.getMap().hasImage(imageKey)) {
									mapRef.current.getMap().addImage(imageKey, image)
								}
							})
						} else if (imageValue.data && !mapRef.current?.getMap().hasImage(imageKey)) {
							// Image already loaded, add if it doesn't exist yet
							mapRef.current.getMap().addImage(imageKey, imageValue.data)
						}
					})
				}}
				onMouseMove={evt => {
					if (isRouting || routeMenuOpened) {
						const closestFeature = getClosestFeature(evt)

						// Handle routing events
						debouncedJourneyTrackerMove(closestFeature)
						deboundedJourneyTrackerFreeMove(evt)

						// Handle route editing events
						if (routeMenuOpened && !isRouting && closestFeature) {
							popupLayersRef.current?.setEdgePopup({
								longitude: evt.lngLat.lng,
								latitude: evt.lngLat.lat,
								ogc_fid: closestFeature.properties.ogc_fid,
								route:
									closestFeature.properties.route_number &&
									`${closestFeature.properties.route_number} ${closestFeature.properties.route_name}`,
								image_name: generateImageURL({ imageName: closestFeature.properties.image_name }, 120),
							})
						} else {
							popupLayersRef.current?.setEdgePopup(null)
						}

						// Change cursor to pointer if hovering over interactable feature
						if (closestFeature && !journeyTrackerDragging.dragging) {
							mapRef.current.getMap().getCanvas().style.cursor = 'pointer'
						} else {
							mapRef.current.getMap().getCanvas().style.cursor = ''
						}
					}
				}}
				onMouseDown={evt => {
					popupLayersRef.current?.setJourneyTrackerPopup(null) // hide info popup
					if (isRouting) {
						const closestFeature = getClosestFeature(evt)
						if (
							[MAP_LAYERS.journeyLayer.id, MAP_LAYERS.myRoutesRoutingStops.id].includes(closestFeature?.layer.id) ||
							(closestFeature?.layer.id === MAP_LAYERS.userPlacesLayer.id && closestFeature?.properties.enum)
						) {
							// Enable dragging if closest feature is journey line or stop, or user place with enum property (stop)
							const coordinates = [evt.lngLat.lng, evt.lngLat.lat]
							if (closestFeature.layer.id === MAP_LAYERS.journeyLayer.id) {
								// Closest feature is the journey line
								setJourneyTrackerDragging({
									location: evt.point,
									coordinates,
									dragging: true,
									existingPoint: null,
								})
							} else {
								// Closest feature is a point
								setJourneyTrackerDragging({
									location: evt.point,
									coordinates,
									dragging: true,
									existingPoint: closestFeature,
								})
							}
						}
					}
				}}
				onMouseUp={evt => {
					if (journeyTrackerDragging.dragging) {
						const xDiff = Math.abs(evt.point.x - journeyTrackerDragging.location.x)
						const yDiff = Math.abs(evt.point.y - journeyTrackerDragging.location.y)
						if (xDiff >= 5 || yDiff >= 5) {
							// Count over 5 pixel movement as drag
							updateMyRouteStops(evt)
						}
						// Disable journey tracker dragging
						setJourneyTrackerDragging({ ...journeyTrackerDragging, dragging: false })
					}
				}}
				onClick={async evt => {
					if (routeMenuOpened || isRouting) {
						const closestFeature = getClosestFeature(evt)

						if (isRouting) {
							if (myRoutesOpened && closestFeature?.layer.id === MAP_LAYERS.userPlacesLayer.id) {
								// Clicked a user place, find the original feature as if it was clicked on search results
								handlePlaceClicked(
									userPlaces.features.find(feature => feature.properties.id === closestFeature.properties.id),
								)
								return
							} else {
								// Normal routing actions
								if (
									isRouting &&
									myRouteStops.start.features.length === 1 &&
									myRouteStops.destination.features.length === 1
								) {
									return
								}

								const place = await getPlace({ lon: evt.lngLat.lng, lat: evt.lngLat.lat })

								if (myRouteStops.start.features.length === 0) {
									setMyRouteStops({
										start: {
											type: 'FeatureCollection',
											features: [
												{
													type: 'Feature',
													geometry: {
														type: 'Point',
														coordinates: [evt.lngLat.lng, evt.lngLat.lat],
													},
													properties: { enum: 'A', name: place.data.r_node_name || 'Wegpunkt A' },
												},
											],
										},
										destination: myRouteStops.destination,
										viaStops: myRouteStops.viaStops,
									})

									return
								}

								if (myRouteStops.destination.features.length === 0) {
									setMyRouteStops({
										start: myRouteStops.start,
										destination: {
											type: 'FeatureCollection',
											features: [
												{
													type: 'Feature',
													geometry: {
														type: 'Point',
														coordinates: [evt.lngLat.lng, evt.lngLat.lat],
													},
													properties: {
														enum: 'B',
														name: place.data.r_node_name || 'Wegpunkt B',
													},
												},
											],
										},
										viaStops: myRouteStops.viaStops,
									})
								}
							}
						} else if (routeMenuOpened) {
							if (isEditingRouteStartPoint) {
								// If editing route start point, set new point and return immediately
								setRouteStartPointFeature({
									type: 'Feature',
									geometry: {
										type: 'Point',
										coordinates: [
											parseFloat(evt.lngLat.lng.toFixed(7)),
											parseFloat(evt.lngLat.lat.toFixed(7)),
										],
									},
								})
								setIsEditingRouteStartPoint(false)
								return
							}

							// Do not allow clicking expired edges
							if (closestFeature && !closestFeature.properties.expired_at) {
								const clickedOgcFid = closestFeature.properties.ogc_fid
								if (selectedRouteData.features.find(feature => feature.properties.ogc_fid === clickedOgcFid)) {
									// Clicked edge already belongs to the route, highlight it
									setClickedOgcFid(clickedOgcFid)
								} else if (
									!selectedFeatures.features.find(feature => feature.properties.ogc_fid === clickedOgcFid)
								) {
									// Clicked edge has not already been selected
									let newSelectedFeatures = [...selectedFeatures.features]
									// Get feature from original data, event geometry does not always cover the whole edge for some reason
									const originalFeature = props.data.features.find(
										feature => feature.properties.ogc_fid === clickedOgcFid,
									)

									// Add clicked edge to selected features, if it has not been marked as unselectable
									if (clickedOgcFid !== unselectableEdgeRef.current) {
										newSelectedFeatures.push({
											...originalFeature,
											properties: {
												...originalFeature.properties,
												color: '#5FABE3',
												lngLat: evt.lngLat,
											},
										})
									}
									// Clear unselectable edge
									unselectableEdgeRef.current = null

									// Find overlapping feature from selected features
									// TODO: Use reversed_fid property when available with directional network update
									const overlappingFeature = selectedFeatures.features.find(
										feature =>
											JSON.stringify([...feature.geometry.coordinates].reverse()) ===
											JSON.stringify(originalFeature.geometry.coordinates),
									)
									if (overlappingFeature) {
										// Filter out previously selected overlapping feature
										newSelectedFeatures = newSelectedFeatures.filter(
											feature => feature.properties.ogc_fid !== overlappingFeature.properties.ogc_fid,
										)
										// Keep track of the overlapping edge so that third click at the same
										// location will clear the selection and selected edge is not flipped indefinitely
										unselectableEdgeRef.current = overlappingFeature.properties.ogc_fid
									}

									setSelectedFeatures({
										type: 'FeatureCollection',
										properties: {},
										features: newSelectedFeatures,
									})
								} else {
									// Remove feature from the list / unclick
									setSelectedFeatures({
										type: 'FeatureCollection',
										properties: {},
										features: [
											...selectedFeatures.features.filter(
												feature => feature.properties.ogc_fid !== clickedOgcFid,
											),
										],
									})
								}
							}
						}
					}
				}}>
				<GeolocateControl
					style={routeMenuOpened ? { right: 6, top: 20, position: 'fixed' } : geoLocateControlStyle}
					positionOptions={{ enableHighAccuracy: true }}
					trackUserLocation={true}
				/>
				<Source id='overlay' type='geojson' data={selectedOverlay}>
					{routeMenuOpened ? <Layer {...MAP_LAYERS.overlayLayer} /> : ''}
				</Source>
				{myRoutesOpened && !isRouting && (
					<Fragment>
						<Source id='userRoutes' type='geojson' data={renderUserRoutes()}>
							<Layer {...MAP_LAYERS.userRoutesOutlineLayer} />
							<Layer {...MAP_LAYERS.userRoutesLayer} />
						</Source>
						<Source id='my-user-routes-routing-connections' type='geojson' data={renderUserRoutesStopConnections()}>
							<Layer {...MAP_LAYERS.myRoutesUserRoutingConnections} />
						</Source>
						<Source id='userRoutesStops' type='geojson' data={renderUserRoutesStops()}>
							<Layer {...MAP_LAYERS.userRoutesStopsLayer} />
							<Layer {...MAP_LAYERS.userRoutesStopsTextLayer} />
						</Source>
					</Fragment>
				)}
				<Source id='selectedRoute' type='geojson' data={selectedRouteData}>
					<Layer {...MAP_LAYERS.selectedRouteOutlineLayer} />
					<Layer {...MAP_LAYERS.selectedRouteLayer} />
					<Layer {...MAP_LAYERS.selectedRouteDirectionLayer} />
				</Source>
				<Source id='network' type='geojson' data={networkData}>
					{routeTypeChooser !== 'myRoutes' && <Layer {...MAP_LAYERS.networkLayer} beforeId='road-label-simple' />}
				</Source>
				<Source id='classified-features-layer' type='geojson' data={classifiedFeatures}>
					{!routeMenuOpened && !myRoutesOpened && (
						<Layer {...MAP_LAYERS.classifiedFeaturesLayer} beforeId='road-label-simple' />
					)}
				</Source>
				<Source id='feature-selected' type='geojson' data={selectedFeatures}>
					<Layer {...MAP_LAYERS.featureSelectedLayerBorder} />
					<Layer {...MAP_LAYERS.featureSelectedLayer} />
					<Layer {...MAP_LAYERS.featureSelectedDirectionLayer} />
				</Source>
				<Source
					id='pin-connections'
					type='geojson'
					data={
						routeMenuOpened && adminOption === 'images'
							? pinConnectionsFeatures
							: { type: 'FeatureCollection', features: [] }
					}>
					<Layer {...MAP_LAYERS.pinConnectionsLayer} />
				</Source>
				<Source
					id='images-pins'
					type='geojson'
					data={
						routeMenuOpened && adminOption === 'images'
							? imagesPinsFeatures
							: { type: 'FeatureCollection', features: [] }
					}>
					<Layer {...MAP_LAYERS.imagesPinsLayer} />
				</Source>
				<Source
					id='images-pins-origin'
					type='geojson'
					data={
						routeMenuOpened && adminOption === 'images'
							? imagesPinsOriginFeatures
							: { type: 'FeatureCollection', features: [] }
					}>
					<Layer {...MAP_LAYERS.imagesOriginPinsLayer} />
				</Source>
				<Source id='journey' type='geojson' data={journeyFeatureCollection}>
					<Layer {...MAP_LAYERS.journeyLayerOutline} />
					<Layer {...MAP_LAYERS.journeyLayer} />
					{isJourneyLoading && <Layer {...MAP_LAYERS.journeyLoadingLayer} />}
				</Source>
				<Source id='my-routes-routing-connections' type='geojson' data={routeConnections}>
					<Layer {...MAP_LAYERS.myRoutesRoutingConnections} />
				</Source>
				<Source
					id='my-routes-stops'
					type='geojson'
					data={{
						type: 'FeatureCollection',
						features: [
							...myRouteStops.start.features,
							...myRouteStops.viaStops.features,
							...myRouteStops.destination.features,
						],
					}}>
					<Layer {...MAP_LAYERS.myRoutesRoutingStops} />
					<Layer {...MAP_LAYERS.myRoutesRoutingStopsText} />
				</Source>
				<Source id='my-selected-place' type='geojson' data={mySelectedPlace}>
					<Layer {...MAP_LAYERS.mySelectedPlaceLayer} />
				</Source>
				{myRoutesOpened && isRouting && (
					<Source id='user-places' type='geojson' data={userPlaces}>
						<Layer {...MAP_LAYERS.userPlacesLayer} />
						<Layer {...MAP_LAYERS.userPlacesTextLayer} />
					</Source>
				)}
				<Source id='feature-highlighted' type='geojson' data={highlightedFeatures}>
					{routeMenuOpened && <Layer {...MAP_LAYERS.featureHighlightedLayer} />}
				</Source>
				<Source id='start-point' type='geojson' data={routeStartPointFeature}>
					{routeMenuOpened && !(adminOption === 'edge_status') && <Layer {...MAP_LAYERS.startPointLayer} />}
				</Source>
				<PopupLayers routeMenuOpened={routeMenuOpened} isRouting={isRouting} ref={popupLayersRef} />
			</MapGL>
			<DesktopMenu />
		</MapProvider>
	)
}

export default Map
